
#top-cart i.icon-shopping-cart {
    font-size: 20px;
}
#top-cart.header-misc-icon > a {
    height:22px;
    line-height: 22px;
    transition: color 0s ease-in-out;
}
#top-cart .top-cart-number {
    top: -11px;
    left: 13px;
    font-size: 14px;
    color: #FFF;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;

    font-weight: 700;
    font-family: 'Poppins', sans-serif;
}

/*
.top-cart-items {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
*/

/*
.top-cart-content .top-cart-action .top-checkout-price {
    margin-right:20px;
}

/* We apply specific style for top cart content when opened as tooltip */
/*
@media (min-width: 992px) {
    .top-cart-content {
        opacity: 0;
        z-index: -2;
        position: absolute;
        width: auto;
        background-color: var(--content-background-color);
        box-shadow: 0px 20px 50px 10px rgba(0, 0, 0, 0.05);
        border: 1px solid #EEE;
        border-top: 2px solid var(--secondary-color);
        right: -15px;
        left: auto;
        top: calc(100% + 2px);
        margin: -10000px 0 0;
        -webkit-transition: opacity .5s ease, top .4s ease;
        -o-transition: opacity .5s ease, top .4s ease;
        transition: opacity .5s ease, top .4s ease;
    }
}

/* We apply specific style for top cart content when opened as fixed from the right */
/*
@media (max-width: 991.8px) {
    .top-cart-open .top-cart-content {
        width: auto;
    }
}

.top-cart-content .top-cart-title .btn-close {
    display: none;
}

@media (max-width: 991px) {
    .top-cart-content .top-cart-title .btn-close {
        display: block;
        position:absolute;
        top:10px;
        right:10px;
    }
}
*/


